import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const recipes = [
  {
    id: 1,
    image:
      'https://tailwindcss.com/_next/static/media/jucy-beef-burger.346917d5ccb0b705233bb1665ffb63f6.jpg',
    title: 'Hank’s Juiciest Beef Burger',
    time: 20,
    difficulty: 'Easy',
    servings: 4,
    author: 'Hank Douglas',
    rating: 4.94,
  },
  {
    id: 2,
    image:
      'https://tailwindcss.com/_next/static/media/jucy-beef-burger.346917d5ccb0b705233bb1665ffb63f6.jpg',
    title: 'Hank’s Juiciest Beef Burger',
    time: 20,
    difficulty: 'Easy',
    servings: 4,
    author: 'Hank Douglas',
    rating: 4.94,
  },
  {
    id: 3,
    image:
      'https://tailwindcss.com/_next/static/media/jucy-beef-burger.346917d5ccb0b705233bb1665ffb63f6.jpg',
    title: 'Hank’s Juiciest Beef Burger',
    time: 20,
    difficulty: 'Easy',
    servings: 4,
    author: 'Hank Douglas',
    rating: 4.94,
  },
  {
    id: 4,
    image:
      'https://tailwindcss.com/_next/static/media/jucy-beef-burger.346917d5ccb0b705233bb1665ffb63f6.jpg',
    title: 'Hank’s Juiciest Beef Burger',
    time: 20,
    difficulty: 'Easy',
    servings: 4,
    author: 'Hank Douglas',
    rating: 4.94,
  },
  {
    id: 5,
    image:
      'https://tailwindcss.com/_next/static/media/jucy-beef-burger.346917d5ccb0b705233bb1665ffb63f6.jpg',
    title: 'Hank’s Juiciest Beef Burger',
    time: 20,
    difficulty: 'Easy',
    servings: 4,
    author: 'Hank Douglas',
    rating: 4.94,
  },
  {
    id: 6,
    image:
      'https://tailwindcss.com/_next/static/media/jucy-beef-burger.346917d5ccb0b705233bb1665ffb63f6.jpg',
    title: 'Hank’s Juiciest Beef Burger',
    time: 20,
    difficulty: 'Easy',
    servings: 4,
    author: 'Hank Douglas',
    rating: 4.94,
  },
  {
    id: 7,
    image:
      'https://tailwindcss.com/_next/static/media/jucy-beef-burger.346917d5ccb0b705233bb1665ffb63f6.jpg',
    title: 'Hank’s Juiciest Beef Burger',
    time: 20,
    difficulty: 'Easy',
    servings: 4,
    author: 'Hank Douglas',
    rating: 4.94,
  },
  {
    id: 8,
    image:
      'https://tailwindcss.com/_next/static/media/jucy-beef-burger.346917d5ccb0b705233bb1665ffb63f6.jpg',
    title: 'Hank’s Juiciest Beef Burger',
    time: 20,
    difficulty: 'Easy',
    servings: 4,
    author: 'Hank Douglas',
    rating: 4.94,
  },
];

const IndexPage = () => (
  <Layout page="modules">
    <Seo title="modules" />
    {/* This example requires Tailwind CSS v2.0+ */}
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
            <nav
              className="relative flex items-center justify-between sm:h-10 lg:justify-start"
              aria-label="Global"
            >
              <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <a href="#/">
                    <span className="sr-only">Workflow</span>
                    <img
                      className="h-8 w-auto sm:h-10"
                      src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                      alt=""
                    />
                  </a>
                  <div className="-mr-2 flex items-center md:hidden">
                    <button
                      type="button"
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Open main menu</span>
                      {/* Heroicon name: outline/menu */}
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                <a
                  href="#/"
                  className="font-medium text-gray-500 hover:text-gray-900"
                >
                  Product
                </a>

                <a
                  href="#/"
                  className="font-medium text-gray-500 hover:text-gray-900"
                >
                  Features
                </a>

                <a
                  href="#/"
                  className="font-medium text-gray-500 hover:text-gray-900"
                >
                  Marketplace
                </a>

                <a
                  href="#/"
                  className="font-medium text-gray-500 hover:text-gray-900"
                >
                  Company
                </a>

                <a
                  href="#/"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Log in
                </a>
              </div>
            </nav>
          </div>

          {/*
                                      Mobile menu, show/hide based on menu open state.

                                      Entering: "duration-150 ease-out"
                                        From: "opacity-0 scale-95"
                                        To: "opacity-100 scale-100"
                                      Leaving: "duration-100 ease-in"
                                        From: "opacity-100 scale-100"
                                        To: "opacity-0 scale-95"
                                    */}
          <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt=""
                  />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  >
                    <span className="sr-only">Close main menu</span>
                    {/* Heroicon name: outline/x */}
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1">
                <a
                  href="#/"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                >
                  Product
                </a>

                <a
                  href="#/"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                >
                  Features
                </a>

                <a
                  href="#/"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                >
                  Marketplace
                </a>

                <a
                  href="#/"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                >
                  Company
                </a>
              </div>
              <a
                href="#/"
                className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"
              >
                Log in
              </a>
            </div>
          </div>

          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Data to enrich your</span>
                <span className="block text-indigo-600 xl:inline">
                  online business
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui
                lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat
                fugiat aliqua.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="#/"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                  >
                    Get started
                  </a>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="#/"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                  >
                    Live demo
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
          alt=""
        />
      </div>
    </div>
    <div className="inner">
      <Recipes recipes={recipes} />
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="lg:w-1/3 lg:mb-0 mb-6 p-4">
              <div className="h-full text-center">
                <img
                  alt="testimonial"
                  className="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                  src="https://dummyimage.com/302x302"
                />
                <p className="leading-relaxed">
                  Edison bulb retro cloud bread echo park, helvetica stumptown
                  taiyaki taxidermy 90's cronut +1 kinfolk. Single-origin coffee
                  ennui shaman taiyaki vape DIY tote bag drinking vinegar cronut
                  adaptogen squid fanny pack vaporware.
                </p>
                <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4" />
                <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
                  HOLDEN CAULFIELD
                </h2>
                <p className="text-gray-500">Senior Product Designer</p>
              </div>
            </div>
            <div className="lg:w-1/3 lg:mb-0 mb-6 p-4">
              <div className="h-full text-center">
                <img
                  alt="testimonial"
                  className="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                  src="https://dummyimage.com/300x300"
                />
                <p className="leading-relaxed">
                  Edison bulb retro cloud bread echo park, helvetica stumptown
                  taiyaki taxidermy 90's cronut +1 kinfolk. Single-origin coffee
                  ennui shaman taiyaki vape DIY tote bag drinking vinegar cronut
                  adaptogen squid fanny pack vaporware.
                </p>
                <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4" />
                <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
                  ALPER KAMU
                </h2>
                <p className="text-gray-500">UI Develeoper</p>
              </div>
            </div>
            <div className="lg:w-1/3 lg:mb-0 p-4">
              <div className="h-full text-center">
                <img
                  alt="testimonial"
                  className="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                  src="https://dummyimage.com/305x305"
                />
                <p className="leading-relaxed">
                  Edison bulb retro cloud bread echo park, helvetica stumptown
                  taiyaki taxidermy 90's cronut +1 kinfolk. Single-origin coffee
                  ennui shaman taiyaki vape DIY tote bag drinking vinegar cronut
                  adaptogen squid fanny pack vaporware.
                </p>
                <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4" />
                <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
                  HENRY LETHAM
                </h2>
                <p className="text-gray-500">CTO</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

const List = ({ children }) => (
  <ul className="divide-y divide-gray-100">{children}</ul>
);

const NavItem = ({ href, isActive, children }) => (
  <li>
    <a
      href={href}
      className={`block px-4 py-2 rounded-md ${
        isActive ? 'bg-amber-100 text-amber-700' : ''
      }`}
    >
      {children}
    </a>
  </li>
);

const Nav = ({ children }) => (
  <nav className="p-4">
    <ul className="flex space-x-2">{children}</ul>
  </nav>
);

const ListItem = ({ recipe }) => (
  <article className="p-4 flex space-x-4">
    <img
      src={recipe.image}
      alt=""
      className="flex-none w-18 h-18 rounded-lg object-cover bg-gray-100"
      width="144"
      height="144"
    />
    <div className="min-w-0 relative flex-auto sm:pr-20 lg:pr-0 xl:pr-20">
      <h2 className="text-lg font-semibold text-black mb-0.5">
        {recipe.title}
      </h2>
      <dl className="flex flex-wrap text-sm font-medium whitespace-pre">
        <div>
          <dt className="sr-only">Time</dt>
          <dd>
            <abbr title={`${recipe.time} minutes`}>{recipe.time}m</abbr>
          </dd>
        </div>
        <div>
          <dt className="sr-only">Difficulty</dt>
          <dd> ·{recipe.difficulty}</dd>
        </div>
        <div>
          <dt className="sr-only">Servings</dt>
          <dd> ·{recipe.servings} servings</dd>
        </div>
        <div className="flex-none w-full mt-0.5 font-normal">
          <dt className="inline">By</dt>{' '}
          <dd className="inline text-black">{recipe.author}</dd>
        </div>
        <div className="absolute top-0 right-0 rounded-full bg-amber-50 text-amber-900 px-2 py-0.5 hidden sm:flex lg:hidden xl:flex items-center space-x-1">
          <dt className="text-amber-500">
            <span className="sr-only">Rating</span>
            <svg width="16" height="20" fill="currentColor">
              <path d="M7.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118L.98 9.483c-.784-.57-.381-1.81.587-1.81H5.03a1 1 0 00.95-.69L7.05 3.69z" />
            </svg>
          </dt>
          <dd>{recipe.rating}</dd>
        </div>
      </dl>
    </div>
  </article>
);

// eslint-disable-next-line no-shadow
const Recipes = ({ recipes }) => (
  <div className="divide-y divide-gray-100">
    <Nav>
      <NavItem href="#/" isActive>
        Featured
      </NavItem>
      <NavItem href="#/">Popular</NavItem>
      <NavItem href="#/">Recent</NavItem>
    </Nav>
    <List>
      {recipes.map((recipe) => (
        <ListItem key={recipe.id} recipe={recipe} />
      ))}
    </List>
  </div>
);

export default IndexPage;
